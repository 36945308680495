<template>
    <div>
        <a-form :form="form" @submit="handleSubmit">
            <a-row type="flex" :gutter="24">
                <a-col :span="24" :lg="24" class="mb-24">
                    <a-card :bordered="false" class="header-solid h-full"
                        :bodyStyle="{ paddingTop: 0, paddingBottom: '40px' }">
                        <template #title>
                            <h5 class="mb-0">Ready for other places answer details</h5>
                        </template>
                        <a-row :gutter="[12]">
                            <a-col :span="6">
                                <a-form-item class="mb-10" label="Title" :colon="false">
                                    <a-input v-model="title" placeholder="eg. Title" />
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-card>
                </a-col>
                <a-button type="primary" html-type="submit"> Submit </a-button>
            </a-row>
        </a-form>
    </div>
</template>

<script>
import readyOtherPlaceAPI from '../../api/readyOtherPlace';
import router from '../../router';

export default {
    data() {
        return {
            title: '',
            readyOtherPlaceEditData: null,
            isEdit: false,
            form: this.$form.createForm(this, { title: "editReadyOtherPlace" }),
        };
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, readyOtherPlace) => {
                if (!err) {
                    readyOtherPlace.title = this.title;
                    if (!this.isEdit) {
                        readyOtherPlaceAPI.createReadyOtherPlace(readyOtherPlace).then((response) => {
                            if (response.status == 200) {
                                router.push('/ready-other-places/ready-other-places-list')
                            }
                        }).catch((error) => {
                            console.log(error);
                            router.push('/ready-other-places/ready-other-places-list');
                        })
                    } else {
                        readyOtherPlaceAPI.updateReadyOtherPlace(this.readyOtherPlaceEditData.data.id, readyOtherPlace).then((response) => {
                            if (response.status == 200) {
                                router.push('/ready-other-places/ready-other-places-list')
                            }
                        }).catch((error) => {
                            console.log(error);
                            router.push('/ready-other-places/ready-other-places-list');
                        })
                    }
                }
            });
        },
        async getReadyOtherPlaceById(id) {
            return readyOtherPlaceAPI.getReadyOtherPlace(id);
        }
    },
    async mounted() {
            if (this.$route.params.id !== undefined) {
            this.readyOtherPlaceEditData = await this.getReadyOtherPlaceById(this.$route.params.id); // get readyOtherPlace title by id to edit it
            this.isEdit = true;
            if (this.readyOtherPlaceEditData.code == "SUCCESS") {
                this.title = this.readyOtherPlaceEditData.data.title;
            }
        }
    },
};
</script>
